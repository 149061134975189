// ON LOAD
document.addEventListener("DOMContentLoaded", function () {

});

const Public = (() => {
	return {
		
	};
})();

// CSS
import "./styles/public.scss";
